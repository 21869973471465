import React from 'react';

// === Assets === //
import sallingFondeneLogo from '@assets/img/salling-fondene.png';
import nettoBlackLogo from '@assets/img/logo-black.png';
import zwiazekUkraincowWPolsceLogo from '@assets/img/zwiazek-ukraincow-w-polsce.png';

// === Components === //
import Container from '@components/common/Container';
import Paragraph from '@components/common/Paragraph';

// === Helpers === //
import { useTranslation } from 'gatsby-plugin-react-i18next';

// === Styles === //
import styled from '@emotion/styled';
import { breakpoints, colors, dimensions, fonts, respondFrom, respondTo } from '@styles';
import { css } from '@emotion/react';

const HCBWrapper = styled.div`
  padding-bottom: 38px;

  ${respondFrom(
    breakpoints.lg,
    css`
      padding-bottom: 100px;
    `
  )}
`;

const HCBInner = styled.div``;

const HCBBoxLarge = styled.div`
  background-color: ${colors.ui.grayLight};
  padding: 38px 20px 50px;
  display: flex;
  flex-flow: column;
  margin-bottom: 16px;
  margin-left: -20px;
  width: calc(100% + 40px);

  ${respondFrom(
    breakpoints.lg,
    css`
      flex-flow: row;
      padding: 80px 82px;
      margin-left: 0;
      width: 100%;
      margin-bottom: 24px;

      > div {
        flex: 1;
      }
    `
  )}
`;

const HCBBoxLargeImg = styled.div`
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  margin-right: 88px;
  width: 100%;
  padding: 40px;

  ${respondFrom(
    breakpoints.lg,
    css`
      max-width: 466px;
      height: 350px;
    `
  )}
`;

const HCBSubtitle = styled.p`
  text-transform: uppercase;
  font-family: ${fonts.extraBold};
  font-weight: 800;
  font-size: ${dimensions.fontSize.tiny}px;
  line-height: 19px;
  letter-spacing: 0.3em;
  margin-bottom: 36px;

  ${respondFrom(
    breakpoints.lg,
    css`
      margin: 0;
      font-size: ${dimensions.fontSize.small}px;
    `
  )}

  &.hide-on-desktop {
    ${respondFrom(
      breakpoints.lg,
      css`
        display: none;
      `
    )}
  }

  &.hide-on-mobile {
    ${respondTo(
      breakpoints.lg,
      css`
        display: none;
      `
    )}
  }

  &.small-box {
    margin-bottom: 30px;

    ${respondFrom(
      breakpoints.lg,
      css`
        margin: 0;
      `
    )}
  }
`;

const HCBBoxLargeText = styled.div`
  h2 {
    margin-top: 32px;
    margin-bottom: 24px;

    ${respondFrom(
      breakpoints.lg,
      css`
        margin-top: 35px;
        margin-bottom: 32px;
      `
    )}
  }
`;

const HCBBoxSmallWrapper = styled.div`
  display: flex;
  flex-flow: column;

  ${respondFrom(
    breakpoints.lg,
    css`
      flex-flow: row;
      justify-content: space-between;
    `
  )}
`;

const HCBBoxSmall = styled.div`
  background-color: ${colors.ui.grayLight};
  padding: 38px 20px 50px;
  margin-left: -20px;
  width: calc(100% + 40px);
  margin-bottom: 16px;

  ${respondFrom(
    breakpoints.lg,
    css`
      width: calc(50% - 12px);
      padding: 50px 82px 100px;
      margin-bottom: 0;
      margin-left: 0;
    `
  )}
`;

const HCBBoxSmallImg = styled.div`
  margin-bottom: 20px;

  ${respondFrom(
    breakpoints.lg,
    css`
      margin-top: 50px;
      margin-bottom: 35px;
    `
  )}
`;

const HomepageCompaniesBoxes = () => {
  const { t } = useTranslation();

  return (
    <HCBWrapper>
      <Container>
        <HCBInner>
          <HCBBoxLarge>
            <HCBSubtitle className="hide-on-desktop">
              {t('home_companies_box_large_subtitle')}
            </HCBSubtitle>
            <HCBBoxLargeImg>
              <img src={sallingFondeneLogo} alt={t('home_companies_box_large_image_alt')} />
            </HCBBoxLargeImg>
            <HCBBoxLargeText>
              <HCBSubtitle className="hide-on-mobile">
                {t('home_companies_box_large_subtitle')}
              </HCBSubtitle>
              <h2>{t('home_companies_box_large_title')}</h2>
              <div>
                <Paragraph
                  htmlAsString={t('home_companies_box_large_description')}
                  className="m16"
                />
              </div>
            </HCBBoxLargeText>
          </HCBBoxLarge>
          <HCBBoxSmallWrapper>
            <HCBBoxSmall>
              <HCBSubtitle className="small-box">
                {t('home_companies_box_small_1_subtitle')}
              </HCBSubtitle>
              <HCBBoxSmallImg>
                <img src={nettoBlackLogo} alt={t('home_companies_box_small_1_image_alt')} />
              </HCBBoxSmallImg>
              <div>
                <Paragraph
                  htmlAsString={t('home_companies_box_small_1_description')}
                  className="m16"
                />
              </div>
            </HCBBoxSmall>
            <HCBBoxSmall>
              <HCBSubtitle className="small-box">
                {t('home_companies_box_small_2_subtitle')}
              </HCBSubtitle>
              <HCBBoxSmallImg>
                <img
                  src={zwiazekUkraincowWPolsceLogo}
                  alt={t('home_companies_box_small_2_image_alt')}
                />
              </HCBBoxSmallImg>
              <div>
                <Paragraph
                  htmlAsString={t('home_companies_box_small_2_description')}
                  className="m16"
                />
              </div>
            </HCBBoxSmall>
          </HCBBoxSmallWrapper>
        </HCBInner>
      </Container>
    </HCBWrapper>
  );
};

export default HomepageCompaniesBoxes;
